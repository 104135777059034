@import url(../../style/font.css);
@import url(../my-team-detail/my-team-detail.css);
.header-notification {
  width: 30px;
  text-align: center;
  margin-right: 10px; }

#menu-profile {
  background-color: black; }

.tooltip.in {
  opacity: 1 !important; }

.popup-menu-profile.tooltip {
  right: 10px; }

.popup-menu-profile-inner {
  width: 300px;
  height: 423px;
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.5) !important;
  right: 0;
  background-color: #ffffff;
  font-family: open-sans-semibold;
  opacity: 1 !important;
  transition: none !important;
  padding: 30px 20px !important;
  border-radius: 5px;
  border: 1px solid rgba(151, 151, 151, 0.5);
  display: flex;
  flex-direction: column; }
  .popup-menu-profile-inner .header-name {
    font-size: 12px !important;
    font-family: open-sans-bold !important;
    color: #31CCBC !important;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 20px; }
  .popup-menu-profile-inner .header-avatar {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    object-fit: cover; }
  .popup-menu-profile-inner a {
    width: 267px;
    margin: 10px auto;
    height: 41px;
    font-size: 15px;
    color: #9B9B9B;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0 30px; }
    .popup-menu-profile-inner a .icon-link {
      height: 22px;
      object-fit: contain;
      margin-right: 25px; }
    .popup-menu-profile-inner a:hover {
      background-color: #31CCBC;
      color: #ffffff; }
      .popup-menu-profile-inner a:hover .number-badge {
        color: white; }
    .popup-menu-profile-inner a .number-badge {
      right: 10px;
      left: auto;
      top: 10px;
      background-color: transparent;
      font-size: 17px;
      font-family: open-sans-bold;
      color: #31CCBC; }
  .popup-menu-profile-inner .header-name {
    font-size: 13px;
    font-family: Avenir-Roman;
    color: #C4C4C4;
    margin: 0 10px 0 20px;
    letter-spacing: 2px;
    word-break: break-all !important; }

#notif .popup-menu-profile-inner {
  width: 31%;
  height: 70vh;
  max-height: 700px;
  overflow: hidden;
  overflow-y: scroll; }
  #notif .popup-menu-profile-inner .notif-menu-title {
    color: #31CCBC;
    font-size: 20px; }
  #notif .popup-menu-profile-inner .notif-menu-desc {
    font-size: 13px;
    color: #6D7278;
    margin-bottom: 40px; }
  #notif .popup-menu-profile-inner a img.notif-type-icon {
    position: absolute;
    width: 26px;
    /* height: 20px; */
    left: 35px;
    bottom: 0; }
  #notif .popup-menu-profile-inner a .notif-message {
    word-break: break-word;
    max-width: 317px;
    color: #4A4A4A;
    font-style: normal;
    font-size: 16px; }
    #notif .popup-menu-profile-inner a .notif-message .notif-elapsed-time {
      color: #31CCBC;
      font-style: normal;
      font-size: 13px; }
  #notif .popup-menu-profile-inner a {
    height: auto;
    width: 100%;
    padding: 10px 5px; }
    #notif .popup-menu-profile-inner a img {
      width: 50px;
      height: auto;
      border-radius: 30px; }
  #notif .popup-menu-profile-inner a:hover {
    background-color: initial;
    color: inherit; }
  #notif .popup-menu-profile-inner a.notif-unread {
    background-color: rgba(179, 190, 190, 0.36); }

.holiday-request .holiday-body img {
  width: 70px;
  height: auto; }

.modal-body.holiday-body .title {
  font-family: 'open-sans-bold'; }

.holiday-body .profile-id {
  display: flex;
  gap: 12px;
  position: relative; }

.holiday-body .profile-name {
  text-align: left; }

.holiday-body .profile-name h5 {
  font-size: 18px;
  font-family: 'open-sans-semibold'; }

.holiday-body .profile-name h6 {
  font-size: 16px;
  color: #9b9b9b;
  font-family: 'open-sans-semibold'; }

.holiday-body .profile-details {
  display: flex;
  align-items: start;
  gap: 12px; }

.holiday-body .Already-Scheduled {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative; }

.all-details {
  width: 30%; }

.holiday-body .profile-details h5 {
  font-size: 16px;
  font-family: 'open-sans-extrabold';
  color: #31ccbc; }

.holiday-body .profile-details h6 {
  font-size: 16px;
  font-family: 'open-sans-semibold';
  color: #4a4a4a; }

.holiday-body .Already-Scheduled h6 {
  font-size: 16px;
  font-family: 'open-sans-semibold';
  color: #4a4a4a; }

.holiday-body .Already-Scheduled h5 {
  font-size: 16px;
  font-family: 'open-sans-extrabold';
  color: #31ccbc; }

.holiday-body .modals-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-top: 40px;
  margin-bottom: 5%; }

.holiday-request .holiday-body .header-modal1 {
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }

.modal-open .modal .modal-dialog, .modal-open .modal .modal-content, .modal-open .modal .modal-body .holiday-request {
  width: 868px !important; }

.modal-open .modal .modal-dialog, .modal-open .modal .modal-content, .modal-open .modal .modal-body .holiday-request {
  width: 868px !important; }

.modal-open .modal .modal-dialog, .modal-open .modal .modal-content, .modal-open .modal .modal-body {
  width: 868px !important;
  border-radius: 8px;
  max-width: 100%; }

.modal-open .modal .modal-dialog, .modal-open .modal .modal-content, .modal-open .modal .modal-body {
  width: 868px !important;
  border-radius: 8px;
  max-width: 100%; }

.left-sidebar {
  width: 15%;
  border-right: solid 1px #EFEFEFC7;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

.main {
  width: calc(100% - 260px);
  height: 100vh; }

.sidebar-header {
  height: 120px;
  background: url(../../assets/images/sidebar-top.svg) no-repeat;
  background-size: cover;
  position: relative;
  justify-content: center;
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center center;
  /* Centers the image */ }

.sidebar-header::after {
  content: '';
  display: block;
  background: url(../../assets/images/sidebar-top-overlay.svg) no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 101%;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center center;
  /* Centers the image */ }

.logo-section {
  z-index: 1; }

a.navbar-brand {
  width: 100%;
  margin: 0;
  padding-left: 30px;
  font-size: 14.5px;
  font-weight: 700;
  margin-bottom: 28px; }

.navbar-collapse {
  width: 100%;
  padding: 0; }

.navbar-expand-lg .navbar-nav li.nav-item {
  width: 100%;
  padding-right: 30px; }

.navbar-expand-lg .navbar-nav li.nav-item .nav-link {
  padding-left: 50px;
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
  color: #4a4a4a;
  cursor: pointer; }

.navbar-expand-lg .navbar-nav li.nav-item .nav-link.active {
  background: linear-gradient(135deg, #FFDEA7 0%, #EA6362 100%);
  border-radius: 0 26px 26px 0;
  color: #fff;
  font-weight: 800; }

nav.navbar.navbar-expand-lg {
  padding-top: 60px; }

.header {
  height: 100px;
  padding: 0 30px;
  width: calc(100% - 260px);
  background-color: #fff; }

.organization-dd button:focus {
  box-shadow: none; }

.toolset p {
  font-size: 16px;
  line-height: 22px;
  color: #4A4A4A;
  font-weight: 700; }

.toolset {
  padding: 5px 12px;
  border-radius: 15px;
  width: auto; }

.toolset.active {
  background-color: #31CCBC; }

button.add-roster {
  background: linear-gradient(135deg, #FFDEA7 0%, #EA6362 100%);
  border: none;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  padding: 5px 24px;
  border-radius: 15px; }

.toolbar-section {
  padding-top: 40px;
  width: calc(100% - 315px); }

.main-content {
  padding: 0 30px;
  margin-top: 100px; }

.header_fixed {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  padding: 10px;
  border-radius: 5px; }

.notifications-header-icon {
  width: 90%; }

.drop-down-list-team {
  color: #757575;
  align-items: center;
  border: none !important;
  cursor: pointer;
  font-size: 16px;
  justify-content: flex-start;
  max-width: 260px;
  min-height: 30px;
  padding-left: 15px;
  width: 100%;
  color: #4A4A4A;
  font-family: 'open-sans-bold';
  text-transform: capitalize; }

.drop-down-list-team.background {
  background: #31ccbc;
  border-radius: 4px;
  width: 90%;
  color: #ffffff; }

.dropdown-list-block {
  width: 262px; }

.showteam_icon {
  padding: 5px;
  color: #31CCBC; }

.menu-link {
  cursor: pointer; }

.group-team-list-popup {
  float: left;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  left: 0;
  margin-bottom: 10px;
  max-width: 268px;
  min-width: 290px;
  padding: 20px;
  position: absolute;
  top: 75%;
  width: 100%;
  z-index: 10;
  margin-left: 30px;
  line-height: 35px; }

.team-logo {
  border-radius: 25px; }

.modal-backdrop.fade.in {
  opacity: 0.5; }

.fade:not(.show) {
  opacity: 1; }

.drop-down-list-team.background {
  width: 100%; }

.down_arrow {
  margin: 15px 10px; }

.modal-backdrop {
  background-color: #928E85; }

@media screen and (max-width: 1920px) {
  .sidebar-header {
    height: 135px; } }

.down_arrow.team_select {
  margin-top: 20px; }
