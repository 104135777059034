@import url(../../style/font.css);
.user_management_container .body_container {
  padding-top: 60px !important;
  padding-left: 0px !important; }

.userheader h1 {
  font-family: open-sans-extrabold;
  font-size: 25px;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
  color: #31CCBC;
  margin-top: -15px; }

.user-table th {
  font-family: open-sans-regular;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px;
  text-align: left;
  color: #31CCBC; }

.user-table tr:last-child td {
  border-bottom: none; }

.searchbar-container {
  position: absolute;
  top: 150px;
  right: 20px; }

.searchbar-input {
  width: 274.67px;
  height: 41.22px;
  border-radius: 28.73px; }

.table-body {
  height: '68px';
  top: '454px';
  left: '320px'; }

.user-manage .table-body td {
  border-bottom: 1px solid rgba(144, 144, 144, 0.26); }

.user-manage .table-body .img-text.disabled {
  opacity: 20%;
  font-family: open-sans-regular;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px;
  text-align: center; }

.user-manage .table-body .img-text img {
  min-width: 15px;
  width: 15px;
  height: 15px;
  margin-left: 18px; }

.userimageEllipse {
  display: inline;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 33px; }

.image-container {
  white-space: pre;
  cursor: pointer;
  display: inline-block;
  /* Adjust the display property as needed */
  /* Adjust the padding as needed */ }
  .image-container .img-close {
    width: 19px;
    min-width: 19px;
    height: 19px;
    margin-left: 10px; }

.img-text {
  text-align: center;
  font-family: open-sans-regular;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px; }

.user-search {
  padding: 0;
  justify-content: flex-end;
  align-items: center;
  left: 0;
  bottom: 0px; }

.text-box-search-team {
  border-radius: 100px;
  color: #9B9B9B;
  background-color: Cream;
  font-size: 14px;
  height: 41px;
  width: 274px;
  max-width: 275px;
  margin: 2px;
  border: 1px solid #F9F6EF;
  padding: 15px; }

.icon-search {
  position: absolute;
  right: 30px;
  top: 10px;
  width: 25px;
  height: 25px; }

.Textlist {
  font-family: open-sans-regular;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px;
  text-transform: lowercase; }

.Textlist::first-letter {
  text-transform: uppercase; }

.capitalize {
  text-transform: capitalize; }

.listMember {
  text-transform: lowercase; }

.listMember::first-letter {
  text-transform: uppercase; }

.disabled {
  color: gray;
  cursor: not-allowed;
  opacity: 0.5; }

.new-member {
  Font-family: open-sans-regular;
  width: 100%;
  margin-left: 0px;
  color: #31CCBC;
  white-space: pre;
  font-size: 20px;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 20px;
  justify-content: flex-end; }

.img-plus {
  filter: invert(50%) sepia(94%) saturate(314%) hue-rotate(125deg) brightness(95%) contrast(160%);
  width: 19px;
  min-width: 19px;
  height: 19px;
  margin-left: 10px; }

.group-down {
  position: absolute;
  left: 260px; }

.txt-al {
  color: black; }

.user-manage {
  background: #fbfbfb;
  width: 100%; }

.user-manage .userheader {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  padding-bottom: 0px; }

.user-manage .userheader .user-search {
  position: relative;
  left: 0;
  bottom: 0; }

.user-manage .userheader .icon-search {
  top: 15px;
  right: 30px;
  width: 15px;
  height: 15px; }

.user-manage .userheader .text-box-search-team {
  padding-right: 42px; }

.user-manage .userheader .text-box-search-team::placeholder {
  font-family: open-sans-regular;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left; }

.user_tables {
  padding: 50px;
  padding-top: 0px; }

.team_sizeDiv {
  padding: 50px;
  padding-bottom: 0px; }

.user-manage .user-table th {
  padding-bottom: 22px; }

.user-manage .userimageEllipse {
  margin-bottom: 18px;
  margin-top: 15px; }

.user-manage .new-member {
  font-size: 16px;
  font-weight: 600;
  font-family: open-sans-regular; }

.user-manage .new-member .img-plus {
  margin-right: 15px; }

.user-manage .table-body td.img-text {
  cursor: pointer; }

.main {
  min-height: 100vh; }

.fix-modal.remove-user.fade.in.modal {
  z-index: 999999;
  padding: 0 !important; }

.remove-user {
  font-family: open-sans-regular;
  font-size: 15px;
  font-weight: 600; }

.fix-modal.fade.in.modal.remove-user .modal-content {
  width: 100% !important;
  max-width: 800px !important;
  padding: 0;
  margin: 0 !important; }

.fix-modal.fade.in.modal.remove-user .modal-dialog {
  width: 100% !important;
  max-width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center; }

.fix-modal.fade.in.modal.remove-user .modal-body {
  width: 100% !important;
  max-width: 100% !important;
  padding: 73px 60px; }

.fix-modal.fade.in.modal.remove-user .modal-body .content {
  font-family: open-sans-regular;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px;
  text-align: center; }

.remove-user .logo {
  width: 84px;
  height: 74px; }

.fix-modal.fade.in.modal.remove-user .modal-footers {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around; }

.fix-modal.fade.in.modal.remove-user .login_btn_login {
  max-width: 188px;
  color: #FFF;
  text-align: center;
  font-family: open-sans-regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px; }

.data-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; }

.user-table .user-name {
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 100%; }

.user-table .user-detail {
  display: flex;
  align-items: center; }

.add-staff-sub-title {
  font-family: open-sans-regular;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  width: 70%;
  margin: 0 auto;
  color: #000; }

.new-member {
  float: right !important; }

.userRoleSection {
  width: 320px !important;
  max-width: 100%;
  margin: auto; }

.userRoleSection .select__control {
  width: 100%; }

.userRoleSection .select__placeholder {
  top: auto !important; }

.new-member.new-users {
  width: 100%;
  max-width: 123px;
  height: 28px;
  color: #FFF;
  text-align: center;
  font-family: Avenir-Medium;
  font-size: 7.863px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  padding: 0 45px;
  margin-top: -35px; }

.add-member-popup input::placeholder, .add-member-popup input, .add-member-popup .userRoleSection .select__placeholder {
  color: #9B9B9B;
  font-family: open-sans-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; }

.add-member-popup .select__indicator-separator {
  display: none; }

.add-member-popup .title {
  color: #00CDAC;
  font-family: open-sans-bold;
  font-size: 26px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal !important;
  display: block; }

.add-member-popup .select__value-container {
  padding-left: 0; }

.add-member-popup .select__dropdown-indicator {
  padding-top: 0; }

.add-member-popup .select__dropdown-indicator svg {
  fill: #00CDAC; }

.add-member-popup .menu-left-action.btn-linear {
  margin-top: 82px;
  width: 100%;
  max-width: 241px;
  height: 57px;
  border-radius: 0;
  font-family: Avenir-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; }

.add-member-popup .form-group {
  margin-bottom: 33px; }

.modal-open .modal.add-member-popup .modal-body {
  overflow: hidden !important; }

.modal-open .modal.add-member-popup .modal-content {
  max-height: 100%; }

.modal.add-member-popup .select__menu {
  text-align: left; }

.paginations {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px; }

.arrow-icon,
.arrow-icon-right {
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s; }

.arrow-icon img,
.arrow-icon-right img {
  width: 20px;
  height: 20px; }

.arrow-icon:hover,
.arrow-icon-right:hover {
  background-color: #f0f0f0;
  border-radius: 50%; }

.arrow-icon-right img {
  rotate: 180deg; }

.page-numbers {
  cursor: pointer;
  padding: 10px;
  margin: 0 5px;
  transition: background-color 0.3s; }

/* Active (highlighted) page number */
.page-numbers.active {
  background-color: #31CCBC;
  color: white;
  border-radius: 25%; }

/* Page number hover effect */
.page-numbers:hover {
  background-color: #f0f0f0;
  border-radius: 25%; }

.hidden {
  display: none; }

.head {
  font-family: open-sans-bold;
  font-size: 24px;
  font-weight: 800;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: center;
  color: #00CDAC;
  width: 100%;
  text-align: center;
  height: 33px;
  padding: 10px 290px; }

.team-size {
  font-family: open-sans-regular;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #31ccbc; }

.team-size p {
  font-family: open sans !important; }
