@import url(../../style/font.css);
.tasks-view {
  background: #ffffff !important;
  border-radius: 3px;
  padding: 80px 40px 70px;
  /* Right Content */ }
  .tasks-view .txt-not-task {
    font-size: 45px;
    line-height: 75px;
    font-family: open-sans-semibold;
    color: rgba(49, 204, 188, 0.43);
    text-align: center;
    margin: auto; }
  .tasks-view span {
    font-size: 21px;
    position: relative;
    padding: 10px 0px; }
    .tasks-view span.active {
      border-bottom: 3px solid #31CCBC; }
    .tasks-view span.upcoming_tasks {
      margin-left: 60px; }
    .tasks-view span:hover {
      cursor: pointer; }
  .tasks-view .completed_tasks,
  .tasks-view .upcoming_tasks {
    font-family: open-sans-regular;
    font-size: 21px;
    line-height: 28px;
    color: #4A4A4A; }
  .tasks-view .task-list-view {
    margin: 40px 0 0 0 !important; }
    .tasks-view .task-list-view .task_shortdes {
      margin: 0px; }
    .tasks-view .task-list-view .info-in {
      padding: 8px 25px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      font-family: open-sans-light;
      height: 135px;
      width: 100%; }
    .tasks-view .task-list-view h5,
    .tasks-view .task-list-view p {
      margin: 0px; }
      .tasks-view .task-list-view h5.task_title,
      .tasks-view .task-list-view p.task_title {
        color: #31CCBC;
        font-size: 21px;
        font-family: "Open Sans", sans-serif;
        line-height: 28px;
        max-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        margin-left: -6px;
        text-transform: capitalize; }
    .tasks-view .task-list-view .task_shortdes {
      font-size: 18px;
      color: #4A4A4A;
      line-height: 25px;
      max-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre; }
    .tasks-view .task-list-view .assignto {
      font-size: 16px;
      line-height: 24px;
      color: #9B9B9B;
      max-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre; }
    .tasks-view .task-list-view .completed {
      font-weight: bold;
      font-family: open-sans-bold;
      color: #31CCBC; }
  .tasks-view .left_images {
    min-width: 100px;
    position: relative;
    width: 240px;
    min-width: 240px;
    height: 100%;
    align-items: center;
    display: flex;
    flex-wrap: wrap; }
    .tasks-view .left_images img {
      border-radius: 7px; }
    .tasks-view .left_images .image-3,
    .tasks-view .left_images .image-4 {
      height: 50%;
      width: 63px;
      padding: 3px; }
    .tasks-view .left_images .image-2 {
      height: 70%;
      padding: 3px;
      margin-right: 8px;
      border-radius: 8px; }
    .tasks-view .left_images .image-1 {
      height: 100%;
      float: left;
      padding: 2px;
      border-radius: 10px; }
    .tasks-view .left_images .one_img.wrap_key_0 img {
      width: 100%; }
    .tasks-view .left_images .one_img.wrap_key_1 img {
      width: 50%; }
    .tasks-view .left_images .wrap_left_img_5 img,
    .tasks-view .left_images .wrap_left_img_4 img,
    .tasks-view .left_images .wrap_left_img_3 img,
    .tasks-view .left_images .wrap_left_img_2 img {
      width: 50%;
      float: left;
      padding: 5px;
      border-radius: 15px; }
    .tasks-view .left_images .wrap_left_img_1 img {
      width: 100% !important; }
    .tasks-view .left_images .plus_add {
      position: absolute;
      bottom: 3px;
      left: 66px;
      opacity: 0.6;
      width: 57px;
      height: 54px;
      font-size: 25px;
      color: white;
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      justify-content: center; }
  .tasks-view .shadow-1 {
    margin-bottom: 10px;
    padding: 10px;
    position: relative; }
  .tasks-view .right_content {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 270px); }
  .tasks-view span.btn_task_delete {
    position: absolute;
    right: 15px;
    top: 0; }
    .tasks-view span.btn_task_delete img {
      width: 20px;
      height: 20px; }

.create-task .form-create-task {
  padding: 0; }
  .create-task .form-create-task .form-control {
    padding: 0;
    box-shadow: none; }
  .create-task .form-create-task .date-time-picker .form-control {
    display: flex;
    align-items: center; }

.create-task .button-view {
  position: inherit !important;
  justify-content: flex-end;
  align-items: center;
  height: 80px !important; }
  .create-task .button-view button {
    top: 6px;
    right: 10px;
    width: 240px !important;
    height: 35px !important;
    line-height: 35px !important;
    border-radius: 6px;
    border: 0px !important; }
  .create-task .button-view .btn-add-task {
    height: 54px !important;
    width: 232px !important; }

.create-task .txt-note-task {
  height: 180px;
  background-repeat: repeat-y;
  background-size: 100% 100%, 100% 100%, 100% 45px;
  background-attachment: local;
  border: none !important;
  outline: none !important;
  padding: 0 !important;
  resize: none; }

.create-task .form-column {
  width: 45%;
  min-width: 45%;
  max-width: 45%;
  margin-bottom: 50px; }

.create-task .form-control {
  background-color: transparent;
  transition: none;
  color: #9B9B9B;
  outline: none;
  height: 40px;
  border-radius: 0;
  border-width: 0 0 1px 0 !important;
  border-color: #F4F6F6 !important;
  box-shadow: none; }

.create-task .drop-staff {
  width: 100%;
  max-height: 315px; }
  .create-task .drop-staff .list-staff-choose {
    margin: 10px 0 0 -5px;
    width: calc(100% + 10px);
    max-height: 275px;
    overflow-y: auto; }
    .create-task .drop-staff .list-staff-choose .fa-times {
      width: 20px;
      height: 20px;
      color: #D0021B;
      position: absolute;
      top: 1px;
      right: 0px;
      line-height: 20px;
      cursor: pointer; }
  .create-task .drop-staff .avatar-staff {
    width: 100%;
    height: 100%; }
  .create-task .drop-staff .staff-view {
    position: relative;
    width: 110px;
    margin: 6px 0px 0px 15px;
    height: 102px;
    border-radius: 4px;
    overflow: hidden; }
  .create-task .drop-staff .name-staff {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 11px !important;
    padding: 5px !important;
    line-height: 22px !important;
    font-size: 12px;
    font-family: open-sans-extrabold;
    font-weight: 300;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center; }

.create-task .select__control {
  align-items: flex-start;
  justify-content: flex-end; }
  .create-task .select__control .select__value-container--is-multi {
    margin: 40px 0 0 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .create-task .select__control .select__multi-value__remove {
    position: absolute;
    right: 5px;
    top: 12px;
    width: 12px;
    height: 12px;
    color: white;
    background: red;
    border-radius: 12px;
    padding: 0; }
  .create-task .select__control .select__multi-value {
    position: relative;
    margin: 0;
    width: 110px;
    min-width: 102px;
    background: no-repeat; }
  .create-task .select__control .select__placeholder {
    top: calc((100% - 39px) / 2); }

.create-task .select__control,
.create-task .Select-control,
.create-task .Select-placeholder,
.create-task .Select-input {
  height: 30px;
  min-height: 30px !important; }
  .create-task .select__control:hover,
  .create-task .Select-control:hover,
  .create-task .Select-placeholder:hover,
  .create-task .Select-input:hover {
    border-color: #cccccc !important;
    box-shadow: none; }

.create-task .css-vj8t7z,
.create-task .css-2o5izw {
  border-radius: 0;
  border-width: 0 0 1px 0;
  box-shadow: none;
  border-color: #cccccc; }

.create-task .attach .imgPreview {
  position: relative;
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; }
  .create-task .attach .imgPreview .fa-times {
    font-size: 8px;
    position: absolute;
    right: 0;
    top: 0;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    border-radius: 100%;
    color: white;
    padding: 5px;
    cursor: pointer; }
  .create-task .attach .imgPreview img,
  .create-task .attach .imgPreview input {
    border-radius: 25px;
    padding: 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain; }
  .create-task .attach .imgPreview input {
    opacity: 0; }

.task-detail .form-control[disabled],
.task-detail .form-control[readonly],
.task-detail fieldset[disabled] .form-control {
  background-color: transparent; }

.task-detail .form-control {
  padding: 0 5px !important; }

.task-detail .txt-note-task {
  background-image: none;
  font-size: 16px;
  line-height: 32px;
  font-family: open-sans-regular; }

.task-detail .staff-view {
  position: relative;
  width: 100px;
  margin: 6px 0px 0px 15px;
  height: 90px;
  border-radius: 4px;
  overflow: hidden; }

.task-detail .avatar-staff {
  position: relative;
  width: 110px;
  height: 102px;
  border-radius: 4px;
  overflow: hidden; }

.task-detail .name-staff {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  font-size: 11px;
  line-height: 22px;
  font-family: open-sans-extrabold;
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 5px; }

.task-detail .r {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 12px;
  height: 12px;
  color: white;
  background: red;
  border-radius: 12px;
  padding: 0; }

.task-detail .task-description {
  position: relative;
  word-break: break-word;
  overflow-y: scroll;
  background-color: transparent;
  color: #9B9B9B;
  font-size: 14px; }

.background-grey {
  background: #F4F6F6 !important;
  border: 0px; }

input:focus,
textarea:focus,
select:focus {
  outline: none; }

.toolbar {
  padding: 16px 45px;
  background-color: #fff;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 10px; }

.my-team-detail.create-task.row {
  margin: 0; }

.toolbar .toolbar-image {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  overflow: hidden; }

.toolbar .toolbar-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.toolbar .toolbar-name {
  margin-left: 62px;
  display: flex;
  align-items: center; }

.toolbar .toolbar-name h2 {
  margin: 0;
  color: #4A4A4A;
  font-size: 16px;
  font-family: Open-Sans-regular;
  font-weight: 600;
  letter-spacing: 0.612px; }

.toolbar .toolbar-name img {
  width: 10px;
  transform: rotate(-92deg);
  margin-left: 15px; }

.my-team .back-to-team {
  width: 25%;
  z-index: 999;
  margin-bottom: 0; }

.my-team .title-section {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 50px; }

.task-name {
  position: absolute;
  z-index: 0;
  top: 0; }

.my-team .form-create-task {
  margin-top: 0; }

.my-team.create-task .my-team-detail.create-task .form-create-task input {
  border-radius: 6px;
  background: #F4F6F6;
  padding: 16px 16px 15px 17px !important;
  height: 50px;
  font-family: open-sans-regular; }

.my-team .my-team-detail.create-task .form-create-task input::placeholder {
  color: rgba(116, 116, 116, 0.85);
  font-size: 14px;
  font-family: open-sans-regular;
  line-height: 18.304px;
  letter-spacing: -0.35px;
  opacity: 0.6; }

.create-task .form-column.task-detail,
.create-task .form-column.assign-staff {
  padding: 0 48px; }

.create-task .form-column.task-detail .form-input {
  background-color: transparent !important; }

.instruction-text-area {
  border-radius: 6px !important;
  background: #F4F6F6;
  margin-bottom: 54px; }

.attachment .title.imgPreview {
  width: 100%;
  border-radius: 6px;
  background: #F4F6F6; }

.attachment .drag {
  margin-top: 27px;
  color: #C4C4C4;
  font-size: 12px;
  font-family: Open-Sans-Semibold;
  font-weight: 600;
  float: left;
  width: 100%; }

.my-team-detail .member-view .button-view {
  margin-top: 0; }

.my-team.my-team-detail .member-view .button-view {
  font-family: open-sans-regular !important;
  color: #FFF !important;
  text-align: center !important;
  font-size: 11.52px; }

.display_col.main {
  padding: 21px 33px 57px 13px; }

.create-task .attach .imgPreview {
  display: flex;
  justify-content: center; }

.my-team.my-team-detail .upload-img {
  max-width: 76px;
  position: static !important; }

.create-task .txt-note-task {
  height: 234px; }

.drop-staff svg {
  fill: #48d8c9; }

.drop-staff .select__indicator-separator {
  display: none; }

.drop-staff .select__dropdown-indicator {
  padding: 0 8px; }

.create-task .select__control,
.create-task .Select-control,
.create-task .Select-placeholder,
.create-task .Select-input {
  min-height: 50px !important;
  height: 50px; }

.create-task .select__control .select__value-container--is-multi {
  padding: 12px 15px 19px 17px; }

.create-task .basic-multi-select .select__placeholder {
  top: 25px !important; }

.create-task .basic-multi-select .select__control--is-focused .select__placeholder {
  display: none; }

.create-task .select__menu {
  border-radius: 6px;
  border: 0.5px solid #DBDBDB !important;
  outline: none;
  background: #FFF; }

.create-task .menu-left-action.btn-add-task {
  font-family: open-sans-regular; }

.drop-staff .basic-multi-select .select__value-container.select__value-container--is-multi.select__value-container--has-value {
  margin: 60px 0 0 0 !important; }

.task-fullview {
  min-height: calc(100vh - 105px); }

.tasks-view .txt-not-task.no-content {
  line-height: 75px;
  font-size: 32px; }

.create_task_container .member-view {
  padding: 0px 50px 0 15px !important; }

.create_task_container .create-task .button-view .btn-add-task {
  width: 320px !important; }
