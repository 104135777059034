@import url(../../style/font.css);
.staff-calendar {
  font-family: Avenir;
  font-weight: 400; }
  .staff-calendar .time-tab {
    line-height: 30px;
    font-size: 16px;
    color: #4A4A4A;
    font-family: open-sans-extrabold; }
    .staff-calendar .time-tab span {
      margin: 0 20px;
      cursor: pointer; }
    .staff-calendar .time-tab .active {
      border-bottom: 3px solid #31CCBC; }
  .staff-calendar .time-scroll {
    font-size: 16px;
    color: #31CCBC;
    line-height: 22px;
    min-width: 260px;
    align-items: center;
    font-family: Avenir-Medium; }
    .staff-calendar .time-scroll .fa {
      margin: 0 15px;
      font-size: 25px;
      cursor: pointer; }
  .staff-calendar .calendar-item {
    margin: 10px; }
  .staff-calendar .calendar-view {
    flex-wrap: wrap; }
  .staff-calendar .week-view, .staff-calendar .month-view {
    flex-wrap: wrap;
    margin: 40px -10px 0 -10px; }
  .staff-calendar .month-view {
    flex-wrap: wrap; }
  .staff-calendar .day-item {
    width: 144px;
    height: 144px;
    border-radius: 10px;
    margin: 10px;
    font-family: Avenir-Medium; }
    .staff-calendar .day-item .txt-day {
      font-size: 20px;
      line-height: 27px;
      color: #4A4A4A; }
    .staff-calendar .day-item .txt-hours {
      font-size: 42px;
      line-height: 57px;
      color: #31CCBC; }
    .staff-calendar .day-item .txt-unit {
      font-size: 15px;
      line-height: 20px;
      color: #31CCBC; }
