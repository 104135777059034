@import url(../../style/font.css);
.staff-hours {
  font-family: 'Helvetica', 'Arial', sans-serif;
  border: 1px solid rgba(206, 206, 206, 0.1); }
  .staff-hours .button-not-resp {
    display: flex; }
  .staff-hours .button-resp {
    display: none; }
  .staff-hours .back-to-roster {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    color: #31CCBC;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 15.3px;
    line-height: 19px;
    margin-left: 10px;
    margin-bottom: 30px;
    width: 25%;
    float: left; }
    .staff-hours .back-to-roster img {
      width: 14px;
      height: 23px;
      margin-right: 10px; }
    .staff-hours .back-to-roster:hover {
      cursor: pointer; }
  .staff-hours .title {
    margin-bottom: 30px; }
  .staff-hours .member-view {
    padding: 80px 20px 25px !important; }
  .staff-hours .ava-member {
    max-width: 122px;
    background-color: grey; }
    .staff-hours .ava-member:hover {
      cursor: pointer; }
  .staff-hours .wrap_box.shadow-1 {
    border-radius: 5px;
    margin-bottom: 18px;
    border: 1px solid rgba(206, 206, 206, 0.2); }
  .staff-hours .staffname, .staff-hours .barista, .staff-hours .working {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important; }
  .staff-hours .staffname {
    font-size: 20px;
    line-height: 24px;
    color: #31CCBC;
    position: relative;
    padding-bottom: 10px;
    font-family: 'Helvetica', 'Arial', sans-serif; }
    .staff-hours .staffname:hover {
      cursor: pointer; }
  .staff-hours .barista {
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: #9B9B9B;
    padding-bottom: 10px; }
    .staff-hours .barista:hover {
      cursor: pointer; }
  .staff-hours .working {
    font-size: 14px;
    line-height: 20px;
    color: #555;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 20px;
    white-space: pre; }
    .staff-hours .working:hover {
      cursor: pointer; }
    .staff-hours .working img {
      width: 18px;
      height: 18px;
      margin-right: 0px; }
  .staff-hours .right_content {
    text-align: left;
    padding-left: 50px;
    width: calc(100% - 120px) !important; }
  .staff-hours .topmenu.active {
    color: #31CCBC; }
  .staff-hours .staff_half {
    padding-left: 15px !important;
    padding-right: 15px !important; }

a {
  text-decoration: none; }

@media screen and (max-width: 1310px) {
  .staff-hours .button-not-resp {
    display: none !important; }
  .staff-hours .button-resp {
    display: flex !important; } }
