@import url(../../style/font.css);
.staff-detail .txt-name {
  color: #00CDAC;
  font-size: 20.8px;
  line-height: 25px;
  margin-bottom: 10px;
  font-weight: 500; }

.staff-detail .txt-alt {
  font-size: 16.9px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #9B9B9B;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important; }

.staff-detail .back-to-roster {
  margin-left: 0px; }

.staff-detail .staff-info {
  padding: 20px 25px !important; }
