@import url(../../style/font.css);
.my-team-container.my-team .team-name {
  align-items: center;
  color: #31ccbc;
  font-family: open-sans-extrabold;
  font-size: 14px;
  max-width: 140px;
  text-transform: uppercase; }

.my-team-container.my-team .txt-condensed-extra-bold {
  padding-left: 0px; }

.my-team-container.my-team .team-group {
  margin-left: 0px; }

.welcome_hint .modal-content {
  padding: 0px !important; }

.my-team {
  max-width: 100% !important;
  width: 100% !important;
  text-align: left !important;
  justify-content: flex-start;
  background: white;
  margin: 0 !important;
  font-family: Avenir-Book;
  letter-spacing: 1px;
  overflow: hidden; }
  .my-team .back-to-team {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    color: #31CCBC;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 15.3px;
    line-height: 19px;
    margin-bottom: 30px; }
    .my-team .back-to-team img {
      width: 14px;
      height: 23px;
      margin-right: 10px; }
    .my-team .back-to-team:hover {
      cursor: pointer; }
  .my-team .note-register {
    text-align: left !important; }
  .my-team .team-view {
    margin: 22px -35px 0 -35px !important;
    flex-wrap: wrap;
    padding-bottom: 10px;
    height: auto !important; }
  .my-team .team-group {
    text-align: center;
    margin: 30px 30px 0 30px;
    max-width: 140px; }
  .my-team .txt-condensed-extra-bold {
    color: #31CCBC;
    margin-bottom: 52px; }
  .my-team .ava-team {
    width: 140px;
    height: 140px;
    border-radius: 20px;
    margin-bottom: 10px; }
  .my-team .title-text-box {
    font-size: 18px;
    color: #31CCBC;
    font-weight: 300; }
  .my-team .icon-plus-team {
    border-radius: 100px;
    border: 2px solid #31CCBC;
    width: 26px;
    height: 26px;
    font-weight: 100;
    line-height: 20px;
    margin-left: 10px;
    font-size: 19px;
    padding: 1px 5px; }
  .my-team .form-col {
    display: flex;
    flex-wrap: wrap; }
    .my-team .form-col .form-group {
      max-width: 350px;
      width: 100%;
      margin-right: 40px;
      display: flex;
      align-items: flex-end; }
      .my-team .form-col .form-group .display_row {
        flex-direction: column; }
      .my-team .form-col .form-group .form-label {
        width: 100%;
        white-space: pre;
        font-weight: 300;
        color: #31CCBC;
        padding: 0;
        font-family: Avenir-Medium; }
    .my-team .form-col .form-group:nth-child(3) {
      margin-right: calc(100% - 350px); }
  .my-team .sent-view {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    .my-team .sent-view span {
      width: 100%;
      white-space: pre;
      font-weight: 300;
      color: #31CCBC; }
    .my-team .sent-view .name-sent {
      color: #9B9B9B;
      font-size: 16px;
      margin-top: 22px;
      width: 50%;
      max-width: 300px; }
  .my-team .menu-left-action {
    width: 145px !important;
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: #ffffff !important;
    border: none;
    background-repeat: repeat !important;
    position: absolute;
    right: 40px;
    bottom: -30px;
    outline: none;
    border-radius: 5px; }
  .my-team .img-admin {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    margin-right: 5px; }
  .my-team .btn-edit-team {
    width: 145px !important;
    height: 35px !important;
    line-height: 35px;
    right: 10px;
    bottom: 0; }
  .my-team .input-team-name {
    border: 1px solid #31CCBC;
    color: #31CCBC;
    text-align: center;
    font-family: open-sans-extrabold;
    font-size: 14px;
    height: 20px;
    letter-spacing: 1px; }

.my-team-detail {
  padding: 0px !important; }

.header-txt {
  font-family: open-sans-bold;
  font-size: 24px;
  font-weight: 800;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: center;
  color: #00CDAC;
  height: 33px;
  padding-top: 15px; }

.tip-modal.tool-tip-modal {
  padding: 0 !important;
  width: 100%;
  height: 80%;
  max-width: 641px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  overflow-y: hidden;
  border-radius: 10px !important;
  overflow: hidden;
  margin-top: -60px;
  margin-left: -58px; }

.tip-modal.fade.in.modal.tool-tip-modal .modal-content {
  width: 100% !important;
  max-width: 800px !important;
  padding: 0;
  margin: 0 !important;
  border: none !important; }

.tip-modal {
  overflow: hidden !important; }

.tip-modal.fade.in.modal.tool-tip-modal .modal-dialog {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }

.tip-modal.fade.in.modal.tool-tip-modal .modal-content {
  background-color: transparent; }

.tip-modal.fade.in.modal.tool-tip-modal .tooltip-body {
  background-color: #fff; }

.tip-modal.fade.in.modal.tool-tip-modal .tooltip-bottom {
  background-color: #fff;
  margin: 0; }

.tip-modal.fade.in.modal.tool-tip-modal .modal-body {
  width: 100% !important;
  max-width: 100% !important;
  border-radius: 16px !important; }

.tip-modal.fade.in.tool-tip-modal .modal-body .content {
  font-family: open-sans-regular;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px;
  text-align: center; }

.tool-tip {
  position: absolute;
  left: 206px;
  cursor: pointer; }

.tool-pointer {
  width: 21px;
  height: 21px; }

.tool-tip-modal {
  width: 641px;
  height: 425px;
  top: 264px;
  left: 598px;
  border-radius: 14px;
  padding: 0px; }

.tooltip-header {
  background: #31CCBC;
  height: 201px;
  display: block !important;
  width: 641px;
  margin: 0px; }

.tooltip-header p {
  width: 295px;
  height: 70px;
  position: relative;
  bottom: 95px;
  left: 330px;
  font-family: open-sans-bold;
  font-size: 26px;
  font-weight: 800;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: left;
  color: #FFFFFF;
  overflow-y: hidden; }

.tooltip-img {
  width: 237px;
  height: 136.48px;
  position: relative;
  top: 36px;
  right: 165px; }

.team-icon {
  width: 29px;
  height: 29px;
  position: relative;
  bottom: 60px;
  left: 290px; }

.tooltip-bottom {
  width: 641px;
  height: 274px;
  margin: 0px;
  top: 465px;
  left: 598px; }

.tooltip-bottom > .row {
  margin: 0; }

.tooltip-bottom-cont p {
  width: 498px;
  left: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27.51px;
  letter-spacing: 0px;
  text-align: justify;
  font-family: open-sans-semibold;
  padding: 0px;
  display: inline-flex;
  color: #4a4a4a;
  align-items: end;
  margin: 0px;
  word-spacing: 2px; }

.tooltip-body {
  top: 264px;
  left: 598px;
  border-radius: 14px;
  border: 0.5px solid #31CCBC;
  background-color: transparent !important;
  padding: 0px;
  margin: 0px;
  width: 641px;
  display: contents; }

.change-page {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: -84px;
  height: 23px;
  z-index: 1; }

.change-page button {
  display: inline-block;
  height: 37px;
  border: none;
  font-family: open-sans-bold;
  font-size: 14px !important;
  font-weight: 800 !important;
  background: transparent;
  line-height: 19px;
  color: #31CCBC;
  cursor: pointer; }

.tooltip-bottom_row {
  display: flex;
  flex-direction: column; }

.tooltip-bottom_row img {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  object-fit: cover;
  margin-top: 30px; }

.tooltip-bottom-name {
  width: 56px;
  height: 22px;
  font-family: open-sans-bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #31CCBC; }

.tooltip-bottom-name p {
  overflow-wrap: break-word;
  font-size: 16px;
  margin-top: 10px; }

.team-slider {
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  top: 0px;
  left: 0px;
  margin-right: 5px; }

.tooltip-slider {
  position: absolute;
  top: 140px;
  left: 318px; }

.carousel-control {
  text-shadow: none;
  opacity: 1;
  filter: alpha(opacity=100); }

.carousel-control.left, .carousel-control.right {
  background: none !important;
  filter: none;
  progid: none !important;
  outline: 0; }

.carousel .carousel-control {
  visibility: hidden; }

.carousel:hover .carousel-control {
  display: none; }

.glyphicon-chevron-left, .glyphicon-chevron-right {
  display: none; }

.carousel-indicators {
  padding: 0;
  margin: 0px 0;
  position: relative;
  bottom: 195px !important;
  left: 7% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -201px; }

.carousel-indicators li {
  width: 15px;
  height: 5px;
  border-radius: 10px;
  background-color: #888;
  margin: 0 5px;
  cursor: pointer; }

.carousel-indicators .active {
  background-color: #fff;
  margin-right: 6px !important; }

.carousel-item {
  transition: none !important; }

.carousel-indicators li {
  border: none;
  background-color: #FFF;
  opacity: 0.5; }

.carousel-indicators li.active {
  opacity: 1;
  width: 10px !important;
  height: 10px !important; }

.carousel-indicators li {
  width: 10px;
  height: 10px; }

.carousel-inner {
  top: -109px;
  width: 85%;
  left: 90px; }

.btn-design {
  width: 150px;
  height: 35px;
  background: linear-gradient(135deg, #FFDEA7 0%, #EA6362 100%);
  border-radius: 20px;
  padding: 10px;
  color: #fff; }

.my-team .team-view {
  margin: 0px !important; }

.my-team .txt-condensed-extra-bold {
  padding-left: 38px; }

.item.next.right, .item.next.left, .item.active, .item.prev.right {
  display: block; }

.item.active.left, .item.active.right, .item {
  display: none; }

.shadow_popup {
  box-shadow: 0 0.5rem 1rem white !important; }
