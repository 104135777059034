@import url(../../style/font.css);
.calendar-container.calendar-view .header-roster-scroll {
  position: fixed;
  z-index: 99;
  left: 0; }
  .calendar-container.calendar-view .header-roster-scroll .search-view {
    background-color: white; }

.calendar-view .button-not-resp {
  display: flex; }

.calendar-view .button-resp {
  display: none; }

.calendar-view .search-view {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  float: right;
  width: 100%;
  margin-right: 50px; }

.calendar-view .tab-view {
  justify-content: flex-end;
  border: none !important;
  margin-bottom: 0 !important;
  padding: 10px 10px 10px 20px !important; }
  .calendar-view .tab-view .tab-item {
    font-size: 17px;
    line-height: 22px;
    color: #4A4A4A;
    border-bottom: 3px solid transparent;
    border-top: 3px solid transparent;
    cursor: pointer;
    padding: 0 10px;
    margin-left: 40px;
    font-weight: 400;
    height: 30px;
    font-family: open-sans-extrabold; }
    .calendar-view .tab-view .tab-item :first-child {
      margin-left: 0; }
  .calendar-view .tab-view .active {
    border-bottom-color: #31CCBC; }

.calendar-view .btn-new-event {
  color: #9B9B9B;
  cursor: pointer;
  margin-left: 20px;
  font-size: 15px;
  line-height: 20px;
  font-family: open-sans-semibold; }

.calendar-view .icon-plus {
  width: 23px;
  height: 23px;
  object-fit: cover;
  margin-right: 10px; }

.calendar-view .member-view {
  padding: 10px 80px 0 30px !important; }

.calendar-view .DayPicker {
  font-family: open-sans-bold; }

.calendar-view .DayPicker-Caption {
  color: #31CCBC;
  font-size: 26px;
  text-transform: uppercase;
  width: 100%;
  font-weight: 500;
  padding: 0 10px; }

.calendar-view .DayPicker-Month {
  margin: 6px 10px 0;
  font-family: open-sans-regular !important; }

.calendar-view .DayPicker-wrapper {
  outline: none; }

.calendar-view .DayPicker-Day {
  outline: white; }

.calendar-view .DayPicker-Day.DayPicker-Day--outside {
  color: #CCC !important;
  background: transparent !important; }

.calendar-view .DayPicker-Day .hasEvent {
  color: #D0021B;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin: auto;
  text-align: center;
  cursor: pointer; }

.calendar-view .DayPicker-Day--today {
  background-color: #31CCBC !important;
  border-radius: 0 !important;
  font-weight: 400;
  color: #ffffff !important; }

.calendar-view .DayPicker-Caption {
  text-align: center; }

.calendar-view .year-view .DayPicker-Caption {
  text-align: left !important;
  margin-bottom: 40px;
  font-family: open-sans-bold; }

.calendar-view .year-view .DayPicker-Month {
  margin-bottom: 50px;
  width: 20%; }

.calendar-view .year-view abbr[title], .calendar-view .year-view abbr[data-original-title] {
  display: none; }

.calendar-view .year-view .DayPicker-Day {
  border: none;
  font-size: 10px;
  width: 38px;
  height: 38px;
  font-family: open-sans-bold; }

.calendar-view .year-view .DayPicker--interactionDisabled .DayPicker-Day {
  color: #9B9B9B;
  font-size: 14px;
  line-height: 20px; }

.calendar-view .month-view .DayPicker-NavBar {
  align-items: center;
  display: flex;
  height: 55px;
  color: #31CCBC;
  position: absolute;
  width: calc(100% - 120px);
  justify-content: space-between;
  margin: 0 60px;
  font-family: Avenir-Medium;
  border-bottom: 1px solid #F8F8F8; }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-left, .calendar-view .month-view .DayPicker-NavBar .fa-angle-right {
    color: #31CCBC;
    position: initial;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px !important;
    padding-top: 5px; }

.calendar-view .month-view .DayPicker-Day {
  font-size: 16px;
  width: 55px;
  height: 55px;
  line-height: 16px;
  color: #797979;
  font-weight: 400;
  background-color: #FBFBFB;
  border-radius: 0;
  border: 1px solid white;
  padding: 0;
  font-family: Avenir-Medium !important; }

.calendar-view .month-view .DayPicker-Weekday {
  font-size: 17px;
  line-height: 25px;
  color: #797979;
  padding-bottom: 18px;
  padding-top: 6px;
  font-family: 'open-sans-regular'; }

.calendar-view .month-view .DayPicker-Caption {
  margin-bottom: 18px; }

.modal-open .modal .modal-content {
  padding: 30px; }

.modal-open .modal .modal-body {
  padding: 0; }
  .modal-open .modal .modal-body .btn-linear-invite {
    width: 195px;
    height: 46px;
    margin-top: 90px; }

.modal-open .tip-modal.modal .modal-dialog {
  width: 641px !important;
  margin-top: 13%;
  margin-left: 35%;
  height: 425px !important;
  position: relative; }

.modal-open .modal .modal-dialog, .modal-open .modal .modal-content, .modal-open .modal .modal-body {
  width: 912px !important;
  border-radius: 8px;
  max-width: 100%; }
  .modal-open .modal .modal-dialog .date-time-picker .fa-calendar, .modal-open .modal .modal-content .date-time-picker .fa-calendar, .modal-open .modal .modal-body .date-time-picker .fa-calendar {
    top: 6px !important; }
  .modal-open .modal .modal-dialog .form-create-event, .modal-open .modal .modal-content .form-create-event, .modal-open .modal .modal-body .form-create-event {
    width: 47% !important;
    justify-content: space-between;
    margin: 0; }
  .modal-open .modal .modal-dialog .form-group .fa-calendar, .modal-open .modal .modal-content .form-group .fa-calendar, .modal-open .modal .modal-body .form-group .fa-calendar {
    border: none; }
  .modal-open .modal .modal-dialog .form-group .rc-time-picker-input, .modal-open .modal .modal-content .form-group .rc-time-picker-input, .modal-open .modal .modal-body .form-group .rc-time-picker-input {
    border-radius: 0;
    border-width: 0 0 1px 0;
    border-color: #DBD7D7;
    height: 30px;
    font-size: 14px;
    color: #9B9B9B;
    padding: 6px 0; }
  .modal-open .modal .modal-dialog .form-group .rc-time-picker, .modal-open .modal .modal-content .form-group .rc-time-picker, .modal-open .modal .modal-body .form-group .rc-time-picker {
    width: 100%; }
  .modal-open .modal .modal-dialog .drop-staff, .modal-open .modal .modal-content .drop-staff, .modal-open .modal .modal-body .drop-staff {
    width: 47%;
    height: 315px; }
    .modal-open .modal .modal-dialog .drop-staff .list-staff-choose, .modal-open .modal .modal-content .drop-staff .list-staff-choose, .modal-open .modal .modal-body .drop-staff .list-staff-choose {
      margin: 10px 0 0 -5px;
      width: calc(100% + 10px);
      max-height: 275px;
      overflow-x: auto; }
      .modal-open .modal .modal-dialog .drop-staff .list-staff-choose .fa-times, .modal-open .modal .modal-content .drop-staff .list-staff-choose .fa-times, .modal-open .modal .modal-body .drop-staff .list-staff-choose .fa-times {
        width: 20px;
        height: 20px;
        color: #D0021B;
        position: absolute;
        top: 1px;
        right: 0px;
        line-height: 20px;
        cursor: pointer; }
    .modal-open .modal .modal-dialog .drop-staff .avatar-staff, .modal-open .modal .modal-content .drop-staff .avatar-staff, .modal-open .modal .modal-body .drop-staff .avatar-staff {
      width: 100%;
      height: 100%; }
    .modal-open .modal .modal-dialog .drop-staff .staff-view, .modal-open .modal .modal-content .drop-staff .staff-view, .modal-open .modal .modal-body .drop-staff .staff-view {
      position: relative;
      width: 109px;
      margin: 5px;
      height: 94px;
      border-radius: 4px;
      overflow: hidden; }
    .modal-open .modal .modal-dialog .drop-staff .name-staff, .modal-open .modal .modal-content .drop-staff .name-staff, .modal-open .modal .modal-body .drop-staff .name-staff {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 11px !important;
      padding: 5px !important;
      line-height: 22px !important;
      font-family: open-sans-extrabold;
      font-weight: 300;
      background-color: rgba(0, 0, 0, 0.5); }
  .modal-open .modal .modal-dialog .form-control, .modal-open .modal .modal-dialog form-control[disabled], .modal-open .modal .modal-content .form-control, .modal-open .modal .modal-content form-control[disabled], .modal-open .modal .modal-body .form-control, .modal-open .modal .modal-body form-control[disabled] {
    width: 100%;
    border: none;
    transition: none;
    background-color: transparent !important;
    border-bottom: 1px solid #C4C4C4 !important;
    height: 40px;
    font-size: 14px;
    color: #9B9B9B;
    line-height: 19px;
    padding: 6px 0;
    border-radius: 0;
    box-shadow: none !important;
    outline: none !important;
    display: flex;
    align-items: center; }
  .modal-open .modal .modal-dialog .select__control, .modal-open .modal .modal-content .select__control, .modal-open .modal .modal-body .select__control {
    align-items: flex-start;
    justify-content: flex-end; }
    .modal-open .modal .modal-dialog .select__control .select__value-container--is-multi, .modal-open .modal .modal-content .select__control .select__value-container--is-multi, .modal-open .modal .modal-body .select__control .select__value-container--is-multi {
      margin: 40px 0 0 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
      height: 105px; }
    .modal-open .modal .modal-dialog .select__control .select__value-container--is-multi-disabled, .modal-open .modal .modal-content .select__control .select__value-container--is-multi-disabled, .modal-open .modal .modal-body .select__control .select__value-container--is-multi-disabled {
      position: absolute;
      top: 0;
      width: 100%; }
    .modal-open .modal .modal-dialog .select__control .select__multi-value__remove, .modal-open .modal .modal-content .select__control .select__multi-value__remove, .modal-open .modal .modal-body .select__control .select__multi-value__remove {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 12px;
      height: 12px;
      color: white;
      background: red;
      border-radius: 12px;
      padding: 0; }
    .modal-open .modal .modal-dialog .select__control .select__multi-value, .modal-open .modal .modal-content .select__control .select__multi-value, .modal-open .modal .modal-body .select__control .select__multi-value {
      position: relative;
      margin: 0;
      width: calc(100% / 3);
      min-width: 98px;
      background: no-repeat; }
    .modal-open .modal .modal-dialog .select__control .select__placeholder, .modal-open .modal .modal-content .select__control .select__placeholder, .modal-open .modal .modal-body .select__control .select__placeholder {
      top: calc((100% - 39px) / 2); }
  .modal-open .modal .modal-dialog .select__control, .modal-open .modal .modal-dialog .Select-control, .modal-open .modal .modal-dialog .Select-placeholder, .modal-open .modal .modal-dialog .Select-input, .modal-open .modal .modal-content .select__control, .modal-open .modal .modal-content .Select-control, .modal-open .modal .modal-content .Select-placeholder, .modal-open .modal .modal-content .Select-input, .modal-open .modal .modal-body .select__control, .modal-open .modal .modal-body .Select-control, .modal-open .modal .modal-body .Select-placeholder, .modal-open .modal .modal-body .Select-input {
    height: 30px;
    min-height: 30px !important; }
    .modal-open .modal .modal-dialog .select__control:hover, .modal-open .modal .modal-dialog .Select-control:hover, .modal-open .modal .modal-dialog .Select-placeholder:hover, .modal-open .modal .modal-dialog .Select-input:hover, .modal-open .modal .modal-content .select__control:hover, .modal-open .modal .modal-content .Select-control:hover, .modal-open .modal .modal-content .Select-placeholder:hover, .modal-open .modal .modal-content .Select-input:hover, .modal-open .modal .modal-body .select__control:hover, .modal-open .modal .modal-body .Select-control:hover, .modal-open .modal .modal-body .Select-placeholder:hover, .modal-open .modal .modal-body .Select-input:hover {
      border-color: #cccccc !important;
      box-shadow: none; }
  .modal-open .modal .modal-dialog .css-1hwfws3, .modal-open .modal .modal-content .css-1hwfws3, .modal-open .modal .modal-body .css-1hwfws3 {
    max-height: 280px;
    overflow-y: auto; }
  .modal-open .modal .modal-dialog .css-vj8t7z, .modal-open .modal .modal-dialog .css-2o5izw, .modal-open .modal .modal-content .css-vj8t7z, .modal-open .modal .modal-content .css-2o5izw, .modal-open .modal .modal-body .css-vj8t7z, .modal-open .modal .modal-body .css-2o5izw {
    border-radius: 0;
    border-width: 0 0 1px 0;
    box-shadow: none;
    border-color: #cccccc; }
  .modal-open .modal .modal-dialog .basic-multi-select.select__menu, .modal-open .modal .modal-content .basic-multi-select.select__menu, .modal-open .modal .modal-body .basic-multi-select.select__menu {
    max-height: 100px; }

.modal-open .modal .modal-body .form-control {
  height: 30px !important;
  box-shadow: none !important; }

.modal-open .modal .title {
  font-size: 22px;
  line-height: 23px;
  display: block;
  margin-top: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 1px; }

.DayPicker {
  font-size: 1.2rem;
  outline: none; }

.event-view {
  padding-top: 40px;
  margin-bottom: 1px; }
  .event-view .txt-event {
    font-size: 22px;
    line-height: 30px;
    color: #D0021B;
    margin-bottom: 15px; }
  .event-view .no-event {
    color: #9B9B9B; }
  .event-view .even-row {
    border-bottom: 1px solid #DDDCDB;
    margin-bottom: 10px;
    margin-top: 4px;
    min-height: 40px; }
    .event-view .even-row span {
      font-size: 18px;
      line-height: 40px;
      min-height: 40px;
      color: #4A4A4A; }

.event-view-popup {
  padding-top: 0 !important; }

.basic-multi-select .select__value-container--is-multi {
  position: absolute !important;
  margin: 0px 0 0 0 !important;
  padding: 0; }

.basic-multi-select .select__control--is-focused .select__value-container--is-multi {
  position: absolute !important;
  margin: 40px 0 0 0 !important;
  padding: 0; }

.basic-multi-select .select__value-container.select__value-container--is-multi.select__value-container--has-value {
  position: absolute !important;
  margin: 40px 0 0 0 !important;
  padding: 0; }

.basic-multi-select .select__control--is-focused .select__placeholder {
  top: calc((100% - 39px) / 2) !important; }

.basic-multi-select .select__placeholder {
  top: 17px !important; }

.css-fhpqww {
  transform: scale(0.65); }

.css-1267l58-MuiButtonBase-root-MuiIconButton-root {
  right: 95px !important; }

.css-92nr1f-MuiButtonBase-root-MuiIconButton-root {
  left: -77px !important; }

.css-1qixffb-MuiButtonBase-root-MuiIconButton-root {
  left: -77px !important; }

.css-1s220mh-MuiButtonBase-root-MuiIconButton-root {
  right: 99px !important; }

.css-epd502 {
  width: 190px !important;
  height: 190px !important; }

.css-u791bf-MuiPickersArrowSwitcher-root-MuiClockPicker-arrowSwitcher {
  right: 3px !important;
  top: 9px !important; }

.css-fhpqww {
  margin-top: -4px !important;
  margin-left: 11px !important; }

.css-eziifo {
  margin-right: 174px !important; }

.css-cysikj {
  left: -77px !important; }

.css-sfp64 {
  right: 95px !important; }

.css-3h73n3 {
  left: -77px !important; }

.css-ag8lru {
  right: 99px !important; }

.event-schedule input {
  font-size: 14px;
  color: #9B9B9B;
  line-height: 19px;
  padding: 6px 0;
  font-family: open-sans-regular;
  border-bottom: 1px solid #c4c4c4; }

.event-schedule fieldset {
  display: none; }

@media screen and (max-width: 1310px) {
  .calendar-view .button-not-resp {
    display: none !important; }
  .calendar-view .button-resp {
    display: flex !important; } }

.open-sans-regular {
  font-family: open-sans-regular; }

.basic-multi-select .select__control .select__placeholder {
  color: #9B9B9B; }

.modal-open .modal.holiday-request .modal-content, .modal-open .modal.holiday-request .modal-dialog, .modal-open .modal.holiday-request .modal-body {
  width: 933px !important; }

.modal.holiday-request .accept-btn, .modal.holiday-request .reject-btn {
  justify-content: center;
  margin-top: 20px;
  border-radius: 10px;
  margin: 15px; }

.staff-hours.calendar-view .search-view {
  display: inline-flex;
  float: right;
  margin-right: 75px; }

.popover {
  max-width: none;
  width: auto !important; }

.popover.bottom > .arrow {
  border-bottom-color: #00000040;
  border-top-width: 0;
  left: 50%;
  margin-left: -11px;
  top: -11px; }

.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.popover > .arrow, .popover > .arrow::after {
  border-color: #0000;
  border-bottom-color: rgba(0, 0, 0, 0);
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0; }

.popover > .arrow {
  border-width: 11px;
  border-top-width: 11px; }
