.loader_layout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99999;
}
.loader_layout_roster {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100%;
  top: 0;
  bottom: 0;
  // left: 0;
  // right: 180px;
  width: 100%;
  z-index: 99999;
}

.wrapper.welcome-page.loader-page {
    position: fixed;
    z-index: 1052;
    background-color: rgba(250, 250, 250, 0.9);
    min-height: 100vh;
    height: 100%;
}
.wrapper.welcome-page.loader-page2 {
  position: fixed;
  z-index: 1052;
  background-color: rgba(250, 250, 250, 0.9);
  min-height: 100vh;
  height: 100%;
  left: 0px;
  top: 0px;
}

.wrapper.welcome-page {
  min-height: 100vh;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}
