@import url(../../style/font.css);
@import url(../my-team-detail/my-team-detail.css);
.feed-page {
  background: white;
  border-radius: 3px; }
  .feed-page .title {
    color: #31CCBC;
    font-size: 20px;
    font-family: inherit;
    line-height: 24px;
    margin-bottom: 30px;
    font-family: open-sans-semibold; }
  .feed-page .feed-name {
    font-family: 'Helvetica', 'Arial', sans-serif;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
    color: #31CCBC;
    position: relative;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important; }
    .feed-page .feed-name .date-times {
      position: absolute;
      right: 0px;
      top: 0px;
      color: #4A4A4A;
      font-size: 13px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;
      font-family: Roboto-Regular; }
    .feed-page .feed-name p {
      font-family: open-sans-bold;
      margin: 0px;
      width: calc(100% - 100px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .feed-page .feed-view {
    font-family: 'Helvetica', 'Arial', sans-serif;
    padding: 40px 60px 60px;
    /*margin: 40px 0 0 0 !important;*/ }
    .feed-page .feed-view .img_left img {
      min-width: 93px;
      object-fit: cover;
      height: 85px;
      border-radius: 5px; }
    .feed-page .feed-view .img_left .ann_user_name {
      text-align: start;
      font-family: inherit;
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
      font-family: open-sans-regular;
      width: 100px;
      text-align: center; }
    .feed-page .feed-view .right_content {
      padding: 0px 15px;
      word-break: break-all; }
    .feed-page .feed-view .team-view {
      margin: 0px; }
      .feed-page .feed-view .team-view .short-descrition {
        color: #9B9B9B;
        overflow: hidden;
        position: relative;
        line-height: 20px;
        font-size: 17px;
        font-family: left;
        max-height: 3.6em;
        text-align: justify;
        padding-right: 1em;
        font-family: open-sans-light;
        margin-right: 10px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important; }
        .feed-page .feed-view .team-view .short-descrition:after {
          /* points in the end */
          content: '';
          /* absolute position */
          position: absolute;
          /* set position to right bottom corner of text */
          right: 0;
          /* set width and height */
          width: 1em;
          height: 1em;
          margin-top: 0.2em;
          /* bg color = bg color under block */
          background: white; }
        .feed-page .feed-view .team-view .short-descrition:before {
          /* points in the end */
          content: '...';
          /* absolute position */
          position: absolute;
          /* set position to right bottom corner of block */
          right: 0;
          bottom: 0; }
      .feed-page .feed-view .team-view .feed-information {
        padding: 1px 10px 10px 10px !important;
        margin: 0px 10px 0px -9px;
        border: none !important;
        margin-bottom: 15px; }
        .feed-page .feed-view .team-view .feed-information .info-in {
          padding-bottom: 10px;
          border-radius: 5px;
          margin-bottom: 8px;
          position: relative; }
  .feed-page .form-control {
    background: #ffffff; }
  .feed-page .shadow-1 {
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    resize: none; }
  .feed-page .input_name {
    margin-top: 45px;
    height: 51px; }
  .feed-page textarea {
    height: 100px !important; }
  .feed-page span.like {
    position: absolute;
    bottom: 10px;
    right: 0; }
    .feed-page span.like img {
      width: 20px;
      height: 17.5px; }
    .feed-page span.like:hover {
      cursor: pointer; }
  .feed-page .titleupload {
    font-family: open-sans-bold;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0 !important; }
    .feed-page .titleupload img {
      width: 28px;
      margin-right: 15px;
      position: relative;
      top: -1px; }
  .feed-page .button-view {
    width: 145px;
    float: right;
    justify-content: flex-end;
    margin-right: -4px; }
    .feed-page .button-view button {
      border: none;
      height: 35px !important;
      line-height: 35px !important;
      border-radius: 3px;
      text-align: center;
      color: #ffffff; }
      .feed-page .button-view button:hover {
        background-position: unset; }
  .feed-page .upload_form {
    display: flex;
    align-items: flex-start;
    padding-top: 15px; }
    .feed-page .upload_form > div {
      line-height: 45px; }
    .feed-page .upload_form:after {
      content: '';
      display: block;
      clear: both; }
  .feed-page .full_w {
    height: 100%; }
  .feed-page input.uploadimg {
    position: absolute;
    top: 0px;
    opacity: 0;
    width: 100%; }
  .feed-page input.uploadimg_ava {
    height: 73%;
    width: 197px;
    position: absolute;
    bottom: 0px;
    opacity: 0; }
  .feed-page .avatar-image {
    width: 197px;
    height: 150px;
    object-fit: contain; }
  .feed-page .wrap-one {
    max-height: 630px;
    overflow-y: auto;
    overflow-x: hidden; }
  .feed-page .wrap-one::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5; }
  .feed-page .wrap-one::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5; }
  .feed-page .wrap-one::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555; }
  .feed-page .content_image img {
    max-width: 100%;
    height: auto;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px; }
  .feed-page .wrap_box {
    padding: 20px 15px;
    border-radius: 8px; }
  .feed-page ::-webkit-input-placeholder {
    /* Chrome */
    font-family: inherit;
    font-size: 16px;
    line-height: 19px;
    color: #9B9B9B;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important; }
  .feed-page :-ms-input-placeholder {
    /* IE 10+ */
    font-family: inherit;
    font-size: 16px;
    line-height: 19px;
    color: #9B9B9B;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important; }
  .feed-page ::-moz-placeholder {
    /* Firefox 19+ */
    font-family: inherit;
    font-size: 16px;
    line-height: 19px;
    color: #9B9B9B;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important; }
  .feed-page :-moz-placeholder {
    /* Firefox 4 - 18 */
    font-family: inherit;
    font-size: 16px;
    line-height: 19px;
    color: #9B9B9B;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important; }

.announcement-information {
  color: #31CCBC;
  font-size: 20px;
  font-family: inherit;
  line-height: 24px;
  margin-bottom: 30px;
  font-family: open-sans-regular; }

.announcement-feeds .feed-information .img_left {
  width: 25%;
  flex: 0 0 25%; }

.announcement-feeds .feed-information .right_content {
  width: 75% !important;
  flex: 0 0 75%;
  padding-right: 0; }

.announcement-feeds .feed-information .right_content .feed-para p {
  font-size: 14px;
  font-family: open-sans-regular; }

.feed-para {
  width: calc(100% - 40px); }
