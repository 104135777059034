@import url(../../style/font.css);
.message-view {
  background: transparent; }
  .message-view .load-more {
    z-index: 10;
    position: absolute;
    left: 0;
    width: calc(100% - 30px);
    text-align: center;
    padding: 5px 15px;
    margin: -10px 15px;
    display: flex;
    justify-content: center; }
  .message-view .list-chat {
    width: 400px;
    max-width: 30%;
    padding: 10px !important;
    margin-right: 10px;
    font-family: open-sans-regular !important; }
    .message-view .list-chat .input-search {
      width: calc(100% - 90px);
      border-radius: 10px;
      padding: 15px;
      background-color: #F9F9F9;
      border: none;
      font-size: 16px;
      color: #C4C4C4;
      height: 48px; }
    .message-view .list-chat .text-cancel {
      width: 68px;
      margin-left: 22px;
      font-family: Avenir-Medium;
      font-size: 20px;
      line-height: 27px;
      color: #FF001F;
      background-color: transparent;
      border: none;
      cursor: pointer; }
  .message-view .new-message {
    width: 100%;
    padding: 10px 10px 20px;
    white-space: pre;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #000;
    font-family: "open-sans-regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
    cursor: pointer; }
    .message-view .new-message .img-plus {
      width: 19px;
      min-width: 19px;
      height: 19px;
      margin-left: 10px; }
  .message-view .member-chat {
    width: 380px;
    height: 80px;
    border-radius: 8px;
    padding: 10px;
    max-width: 95%;
    cursor: pointer;
    margin-bottom: 15px;
    background-color: transparent;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
    .message-view .member-chat .member-chat-name {
      font-size: 17px;
      line-height: 23px;
      width: 240px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre; }
    .message-view .member-chat .member-chat-title {
      font-size: 14px;
      height: 23px;
      width: 240px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      color: #9B9B9B; }
    .message-view .member-chat.active {
      background-color: #31CCBC;
      color: #ffffff !important;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out; }
      .message-view .member-chat.active .member-chat-title {
        color: white !important; }
      .message-view .member-chat.active .member-chat-name {
        color: white !important; }
    .message-view .member-chat img {
      border-radius: 100px;
      min-width: 61px;
      height: 63px;
      width: 63px;
      object-fit: cover;
      margin-right: 22px; }
    .message-view .member-chat .chat-number-badge {
      background-color: #4A90E2;
      border-radius: 100px;
      font-size: 10px;
      line-height: 10px;
      color: #ffffff;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center; }
  .message-view .chat-view {
    padding: 20px !important;
    width: 100% !important;
    min-width: 70% !important;
    max-width: calc(100% - 400px) !important; }
    .message-view .chat-view .list-message {
      max-height: calc(100% - 166px) !important;
      padding: 15px;
      height: 100%;
      align-content: flex-end;
      flex-wrap: wrap; }
    .message-view .chat-view .member-info {
      position: relative;
      flex-wrap: wrap;
      grid-gap: 15px;
      display: flex;
      padding-top: 13px;
      max-height: calc(100% - 455px); }
      .message-view .chat-view .member-info .member-chat {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 22%; }
        .message-view .chat-view .member-info .member-chat .member-chat-name, .message-view .chat-view .member-info .member-chat .member-chat-title {
          width: 100%; }
      .message-view .chat-view .member-info hr {
        width: 95%;
        margin: 10px auto 10px auto;
        border: 0.5px solid #DFDFDF !important; }
    .message-view .chat-view .content-chat {
      width: 100%;
      margin: 20px 0 50px 0;
      height: fit-content; }
      .message-view .chat-view .content-chat img {
        border-radius: 100px;
        width: 48px;
        height: 48px; }
      .message-view .chat-view .content-chat .content-text {
        margin: 0 25px;
        border-radius: 12px 12px 12px 0;
        padding: 13px;
        background-color: #EBEBEB;
        min-width: 140px;
        position: relative;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        word-break: break-word;
        font-family: "open-sans-regular" !important;
        color: #FFF; }
        .message-view .chat-view .content-chat .content-text .time-chat {
          position: absolute;
          font-size: 8px;
          line-height: 15px;
          right: 10px;
          bottom: -13px;
          color: #9B9B9B; }
      .message-view .chat-view .content-chat.is-me {
        display: flex;
        flex-direction: row-reverse; }
        .message-view .chat-view .content-chat.is-me .content-text {
          border-radius: 12px 12px 0px 12px; }
        .message-view .chat-view .content-chat.is-me .time-chat {
          color: #FFF; }
      .message-view .chat-view .content-chat.is-me .content-text {
        background-color: #31CCBC;
        color: #ffffff; }
  .message-view .stick-to-bottom {
    padding: 0px !important;
    width: 100% !important;
    min-width: 70% !important;
    max-width: 100% !important; }
    .message-view .stick-to-bottom .chat-box {
      padding: 0;
      position: relative; }
      .message-view .stick-to-bottom .chat-box textarea {
        max-height: 100px;
        border: none; }
  .message-view .message-loading {
    position: absolute;
    top: -35px;
    left: 0; }
  .message-view .chat-box {
    height: 87px;
    width: 98%;
    position: relative;
    padding: 15px; }
    .message-view .chat-box textarea {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      outline: none;
      border-color: #DDDCDC;
      font-size: 13px;
      line-height: 18px;
      padding: 10px;
      resize: none;
      background-color: #F4F4F4;
      border: none;
      margin-top: -13px; }
    .message-view .chat-box span {
      position: absolute;
      right: 19px;
      top: 41px;
      bottom: 20px;
      font-size: 13px;
      line-height: 18px;
      color: #9B9B9B; }
  .message-view .body_container {
    padding-top: 110px !important; }

.roster-view.message-view .list-chat {
  margin-top: 10px !important; }

.member-chat-role {
  color: #ff0000;
  font-size: 14px;
  text-transform: lowercase; }

.member-chat-title {
  text-transform: capitalize;
  color: #9B9B9B;
  font-family: "open-sans-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3px; }

.member-chat-name {
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 17px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  text-transform: capitalize; }

.create-group .btn-edit-group {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 206.8px !important;
  border-radius: 4.8px;
  font-family: "Avenir-Medium";
  top: -4px;
  left: 83px; }

.list-border-bottom {
  border: 1px solid #eeeeee !important; }

.group-images {
  display: flex;
  overflow-x: hidden;
  flex: 0 0 33.3%; }

.chat-member-name {
  flex: 0 0 58.33%;
  width: 100%;
  overflow: hidden;
  margin-left: 6px; }

.profile-images1 {
  position: relative;
  right: 70px; }

.profile-images2 {
  position: relative;
  right: 130px; }

.group-chat-close {
  position: relative;
  margin-right: 22px; }

.message-view .member-chat .group-chat-close img {
  margin: 0; }

.group-chat-close::before {
  content: url("../../assets/images/group-close.png") !important;
  width: 100%;
  height: 100%;
  background: #31CCBC;
  position: absolute;
  left: 0;
  opacity: 0.78;
  top: 0;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.not-started {
  width: 100%;
  height: calc(100% - 188px) !important;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative; }

.not-started p {
  color: #31CCBC;
  text-align: center;
  font-family: 'open-sans-bold';
  font-size: 37px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  opacity: 0.1956;
  max-width: 520px;
  text-align: center; }

.message-view .chat-view .list-message {
  padding: 10px;
  height: 100%;
  align-content: flex-end; }

.message-view .chat-view .member-info {
  max-height: 100%;
  height: 60px; }

.time-chat {
  top: 37px;
  left: 49px; }

.memberChatList {
  width: 35px;
  height: 35px;
  border-radius: 35px; }

.message-view .chat-box span {
  font-size: 13px;
  font-weight: 400;
  font-family: "open-sans-regular";
  color: #9B9B9B; }

.listOfChat {
  margin-top: 36px;
  padding-left: 25px; }

.message-view .chat-view .content-chat .content-text {
  color: #000;
  text-align: start; }

.message-view .chat-view .content-chat .content-text .time-chat {
  display: flex;
  justify-content: end;
  align-items: end; }

.message-view .list-chat {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px 0px; }

.message-view .chat-view {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px 0px; }

.search_bar {
  border-radius: 100px;
  color: #9B9B9B;
  background-color: Cream;
  font-size: 11px;
  height: 14px;
  width: 179px;
  max-width: 264px;
  margin: 2px;
  border: 1px solid #F9F6EF;
  padding: 15px;
  margin-top: 26px;
  font-family: "open-sans-regular"; }

.img-search {
  position: absolute;
  width: 15px;
  top: 5p;
  height: 15px;
  top: 179px;
  left: 159px; }

.searchDiv {
  display: flex; }
