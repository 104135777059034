@import url(../../style/font.css);
.staff_container .staff-info .calendar-view {
  margin-top: 60px; }

.staff-info {
  font-family: Avenir-Medium; }
  .staff-info .btn-linear-orange {
    width: auto;
    height: 35px !important;
    line-height: 35px !important;
    bottom: auto;
    background-repeat: repeat !important;
    border-radius: 25px !important;
    font-family: open-sans-regular; }
  .staff-info .calendar-view {
    display: flex;
    width: 100%;
    letter-spacing: 0;
    position: relative;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .staff-info .staff-avatar {
    border-radius: 10px;
    width: 170px;
    height: 155px;
    margin-right: 40px;
    object-fit: cover; }
  .staff-info .calendar-item {
    max-width: 166px;
    max-height: 166px;
    border-radius: 10px;
    padding: 0;
    margin: 20px;
    cursor: pointer; }
    .staff-info .calendar-item img {
      width: 100%;
      height: 100%; }
    .staff-info .calendar-item:before {
      content: '';
      padding-top: 100%;
      /* vertical value as  100% equals width */
      float: left; }
  .staff-info .txt-name {
    color: #31CCBC;
    font-size: 21px;
    line-height: 29px;
    margin-bottom: 4px;
    font-weight: 500; }
  .staff-info .txt-note {
    color: #9B9B9B;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 4px; }
    .staff-info .txt-note:nth-child(2), .staff-info .txt-note:nth-child(4) {
      font-family: open-sans-light; }
  .staff-info .txt-calendar-note {
    bottom: 18px;
    white-space: nowrap;
    font-size: 11px;
    color: #DDDCDB;
    z-index: 10;
    position: absolute;
    left: 15px;
    font-family: Avenir-Medium; }
  .staff-info .txt-calendar-work {
    position: absolute;
    bottom: 26px;
    z-index: 10;
    left: 15px;
    font-family: open-sans-bold; }
  .staff-info .txt-work {
    font-size: 17px;
    color: #31CCBC;
    margin-bottom: 4px; }

.go-back {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #31CCBC;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 15.3px;
  line-height: 19px;
  margin-top: 30px;
  position: absolute;
  cursor: pointer; }
  .go-back img {
    width: 14px;
    height: 23px;
    margin-right: 10px; }

.remove-admin {
  width: 60%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px; }

.staff-info .calendar-item, .staff-info .calendar-item img {
  width: 164px !important;
  height: 157px !important; }

#tool-title_0 {
  position: relative !important;
  top: -30px;
  left: -30px; }

span.txt-calendar-note.tool-title {
  color: #DDDCDB;
  font-family: "open-sans-regular";
  font-size: 11.256px !important;
  font-weight: 400 !important; }

#tool-title_1 {
  left: -18px; }

#tool-title_2 {
  left: -51px; }

#tool-title_3 {
  left: -53px; }

#calendar-week, #calendar-month {
  font-size: 16px !important;
  font-weight: 600 !important; }

.staff-calendar .time-scroll {
  font-size: 16px !important;
  font-weight: 400px !important;
  font-family: "open-sans-regular";
  margin-right: 32px; }

.staff-calendar .day-item {
  width: 142px;
  height: 143px;
  padding: 25px; }

.staff-calendar .day-item .txt-day {
  font-size: 20px;
  font-weight: 800;
  font-family: "open-sans-regular"; }

.staff-calendar .day-item .txt-hours {
  font-size: 42px;
  font-weight: 400;
  font-family: "open-sans-regular"; }

.staff-calendar .day-item .txt-unit {
  font-size: 14px;
  font-weight: 400;
  font-family: "open-sans-regular"; }

.staff-calendar .week-view, .staff-calendar .month-view {
  gap: 0px; }

@media screen and (max-width: 2560px) {
  .staff-info .calendar-item {
    max-height: 186px;
    max-width: 264px;
    margin-left: 0px; }
  .staff-info .calendar-item img {
    max-height: 186px;
    max-width: 264px; }
  span.txt-work.txt-calendar-work.tool-name {
    padding-left: 0px;
    top: -64px;
    left: 28px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    position: relative !important; }
  span.txt-calendar-note.tool-title {
    position: relative !important;
    top: -39px;
    left: -22px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500; }
  #calendar-month {
    margin-left: 53px;
    font-family: "open-sans-regular";
    font-size: 20px;
    font-style: normal;
    font-weight: 700; }
  #calendar-week {
    margin-left: 3px;
    font-family: "open-sans-regular";
    font-size: 20px;
    font-style: normal;
    font-weight: 700; } }

@media screen and (max-width: 1440px) {
  .staff-info .staff-avatar {
    width: 169px !important;
    height: 155px !important;
    margin-right: 0px; }
  .staff-info .txt-name {
    color: #00CDAC;
    font-family: "open-sans-regular";
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700 !important;
    padding-left: 39px; }
  .staff-info .txt-note {
    color: #4A4A4A;
    font-family: "open-sans-regular";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    padding-left: 39px; }
  .staff-info .txt-work {
    color: #31CCBC;
    font-family: "open-sans-regular";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    padding-left: 39px; }
  .staff-info .calendar-item {
    width: 164px !important;
    height: 157px !important;
    margin-left: 6px; }
  .staff-info .calendar-item img {
    width: 164px !important;
    height: 157px !important; }
  span.txt-work.txt-calendar-work.tool-name {
    position: relative !important;
    margin-top: -4px !important;
    align-items: start;
    padding-left: 0px;
    top: -45px;
    left: 28px;
    color: #00CDAC;
    font-family: "open-sans-regular";
    font-size: 13px;
    font-style: normal;
    font-weight: 700; }
  span.txt-calendar-note.tool-title {
    position: relative !important;
    top: -30px;
    left: -21px;
    color: #DDDCDB;
    font-family: "open-sans-regular";
    font-size: 11px;
    font-style: normal;
    font-weight: 400; }
  .staff-calendar .time-tab span {
    color: #4A4A4A;
    font-family: "open-sans-regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 600; }
  #calendar-month {
    margin-left: 53px; }
  #calendar-week {
    margin-left: 6px; } }

@media screen and (max-width: 1024px) {
  .staff-info .staff-avatar {
    width: 145px !important;
    height: 122px !important;
    margin-right: 0px; }
  .staff-info .txt-name {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 700 !important; }
  .staff-info .txt-note {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400; }
  .staff-info .calendar-item {
    width: 132px !important;
    height: 123px !important; }
  .staff-info .calendar-item img {
    width: 132px !important;
    height: 123px !important; }
  span.txt-work.txt-calendar-work.tool-name {
    top: -46px;
    left: 19px;
    font-size: 11px !important;
    font-weight: 700; }
  span.txt-calendar-note.tool-title {
    top: -30px;
    left: -21px;
    font-size: 9px;
    font-weight: 400; }
  #calendar-week {
    font-size: 15px;
    font-style: normal;
    font-weight: 600; }
  #calendar-month {
    font-size: 15px;
    font-style: normal;
    font-weight: 600; } }
