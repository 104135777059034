@import url(../../style/font.css);
.my_team_details_container .team-view .my-team-card {
  width: 48%; }

.my_team_details_container .team-view .member-information .memberDetails .info {
  letter-spacing: normal; }
  .my_team_details_container .team-view .member-information .memberDetails .info .fa {
    width: 10px !important; }

.my_team_details_container .my-team {
  width: 100%; }

.my_team_details_container .body_container {
  padding-left: 50px !important; }

.my-team-detail {
  background: transparent;
  padding: 0 !important;
  font-weight: 400; }
  .my-team-detail .search-bar {
    min-height: 50px;
    height: 50px;
    max-height: 50px;
    padding: 0 30px;
    align-items: center;
    border-radius: 3px;
    justify-content: space-between;
    margin-bottom: 13px;
    margin-left: 30px; }
    .my-team-detail .search-bar .team-name {
      letter-spacing: 0.6px;
      padding: 0 20px 0 20px;
      text-align: right;
      font-size: 14px;
      color: #31CCBC;
      font-family: open-sans-extrabold; }
    .my-team-detail .search-bar .logo-team {
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 100px; }
    .my-team-detail .search-bar .no-hover .team-name {
      color: #9B9B9B; }
    .my-team-detail .search-bar .team-name-title {
      padding: 0px !important; }
    .my-team-detail .search-bar .drop-down-list-team {
      border: none !important;
      align-items: center;
      max-width: 260px;
      width: 100%;
      margin: 0 0 0 15px !important;
      justify-content: flex-start;
      padding: 0 40px 0 0 !important;
      cursor: pointer;
      font-size: 16px;
      min-height: 30px; }
      .my-team-detail .search-bar .drop-down-list-team.no-hover {
        background: transparent !important; }
      .my-team-detail .search-bar .drop-down-list-team .team-name {
        line-height: 20px;
        text-align: left;
        max-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        font-size: 14px;
        color: #31CCBC;
        font-family: open-sans-extrabold;
        justify-content: flex-start;
        max-width: none;
        display: block; }
      .my-team-detail .search-bar .drop-down-list-team .background {
        background: #31CCBC;
        border-radius: 4px; }
        .my-team-detail .search-bar .drop-down-list-team .background .team-name {
          color: white !important; }
    .my-team-detail .search-bar .group-team-list-popup {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-width: 300px;
      min-width: 300px;
      border-radius: 5px;
      border: 1px solid #eaeaea;
      background: white;
      padding: 20px;
      z-index: 10; }
      .my-team-detail .search-bar .group-team-list-popup .drop-down-list-team {
        padding: 0 !important;
        margin: 0 0 8px !important;
        justify-content: flex-start; }
        .my-team-detail .search-bar .group-team-list-popup .drop-down-list-team:last-child {
          margin-bottom: 0 !important; }
        .my-team-detail .search-bar .group-team-list-popup .drop-down-list-team:hover {
          background: #31CCBC;
          border-radius: 4px; }
        .my-team-detail .search-bar .group-team-list-popup .drop-down-list-team:hover > .team-name {
          color: #ffffff !important; }
    .my-team-detail .search-bar .fa-angle-down {
      margin-left: 10px;
      color: #9B9B9B;
      font-size: 25px; }
    .my-team-detail .search-bar .group-search {
      padding: 0;
      justify-content: flex-end;
      align-items: center; }
    .my-team-detail .search-bar .text-box-search-team {
      border-radius: 100px;
      color: #9B9B9B;
      padding: 0 20px;
      font-size: 14px;
      height: 30px;
      width: 100%;
      max-width: 275px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
      margin: 2px;
      border: none; }
    .my-team-detail .search-bar .icon-search {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 25px;
      height: 25px; }
    .my-team-detail .search-bar .fa-angle-down {
      color: #31CCBC; }
  .my-team-detail .member-view {
    background: white;
    border-radius: 3px;
    padding: 80px 40px 70px; }
    .my-team-detail .member-view .button-view {
      width: 100%;
      height: 50px;
      margin-bottom: 40px;
      position: absolute;
      justify-content: flex-end;
      align-items: center; }
      .my-team-detail .member-view .button-view button {
        position: initial !important; }
      .my-team-detail .member-view .button-view :last-child {
        margin-left: 25px !important; }
    .my-team-detail .member-view .title {
      font-size: 20px;
      font-family: Avenir;
      font-weight: 400;
      color: #31CCBC;
      position: relative; }
      .my-team-detail .member-view .title button {
        top: 6px;
        right: 10px;
        width: 145px !important;
        height: 35px !important;
        line-height: 35px !important;
        border-radius: 3px; }
    .my-team-detail .member-view .status-pending {
      color: white;
      font-weight: bold;
      position: absolute;
      padding: 5px;
      background: rgba(0, 205, 172, 0.5);
      border-radius: 8px 0 8px 0;
      font-size: 10px;
      letter-spacing: 1px; }
  .my-team-detail .member-name.blue {
    color: #31CCBC; }
  .my-team-detail .member-time {
    font-size: 9px;
    color: #7F8FA4; }
  .my-team-detail .member-name.red {
    color: #ec6e68; }
  .my-team-detail .member-title {
    font-size: 14px;
    color: #9B9B9B;
    margin: 0px 0 10px 0;
    max-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre; }
  .my-team-detail .member-name {
    font-size: 14px;
    margin: 0 0 6px 0;
    max-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 100%;
    font-family: "open-sans-regular";
    font-weight: bold; }
  .my-team-detail .fa-clock-o {
    font-size: 10px;
    margin-right: 6px; }
  .my-team-detail .ava-member {
    min-width: 122px;
    height: 113px;
    object-fit: cover; }
  .my-team-detail .member-information {
    padding: 0 10px 0 0 !important;
    margin-bottom: 30px !important;
    border: none !important; }
    .my-team-detail .member-information .memberDetails .info {
      text-align: left; }
  .my-team-detail .info-in {
    height: 113px;
    border-radius: 8px;
    overflow: hidden;
    width: calc(100% - 30px); }
  .my-team-detail .team-view {
    margin: 40px 0 0 0 !important;
    width: calc(100% + 40px); }
  .my-team-detail .text-view {
    padding-left: 30px;
    width: calc(100% - 150px); }

.icon-close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  padding: 12px; }

.my-team-card {
  position: relative; }

.my-team-close-icon {
  position: absolute; }

.remove-team-member-pop-up {
  right: 0px; }

.icon-remove {
  width: 20px !important;
  height: 20px !important;
  padding: 0;
  top: 10px;
  right: 50px; }

.modal-open .modal {
  display: flex !important;
  justify-content: center;
  align-items: center; }
  .modal-open .modal .modal-content {
    width: 683px !important;
    max-width: 100% !important;
    display: flex;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
  .modal-open .modal .modal-dialog {
    width: 683px !important;
    max-width: 100% !important; }
  .modal-open .modal .modal-body {
    width: 100%;
    max-width: 400px;
    margin: auto;
    font-family: Avenir;
    text-align: center;
    padding: 70px 0; }
    .modal-open .modal .modal-body form {
      width: 320px;
      max-width: 100%;
      margin: auto; }
    .modal-open .modal .modal-body .form-control {
      background: transparent;
      border-bottom: 1px solid #C4C4C4;
      height: 40px;
      font-size: 14px;
      color: #9B9B9B;
      line-height: 19px;
      padding: 6px 0;
      border-radius: 0; }
    .modal-open .modal .modal-body .btn-linear-invite {
      height: 57px;
      width: 241px;
      text-align: center;
      max-width: 100%;
      font-size: 12px;
      margin-top: 80px; }
  .modal-open .modal .title {
    color: #31CCBC;
    font-size: 25px;
    line-height: 36px;
    margin-bottom: 20px; }
  .modal-open .modal .sub-title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    color: #9B9B9B;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: "Open Sans", sans-serif; }

.my-team-detail .member-view .admin-changes, .my-team-detail .member-view .team-changes {
  position: static; }

.remove-member-logo {
  width: 70px;
  height: 64px;
  object-fit: contain; }

.my-team-remove-pop-up {
  right: 0px; }

#popup-change-plan .title {
  font-size: 22px;
  text-transform: capitalize; }

.remove-team-member {
  width: fit-content; }

.add-staff-sub-title {
  font-family: open-sans-regular;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  width: 70%;
  margin: 0 auto; }

.toolset p {
  font-size: 16px;
  line-height: 22px;
  color: #4A4A4A;
  font-weight: 700;
  text-wrap: nowrap; }

.toolset {
  background-color: #F9F9F9;
  padding: 5px 12px;
  border-radius: 15px;
  width: auto; }

.toolset .label {
  color: #4A4A4A;
  font-weight: 700; }

.my-team-detail .icon-search {
  position: absolute;
  width: 25px;
  height: 25px; }

.my-team-detail .task-list-view .member-information {
  width: 100%; }

.my-team-detail .ava-member {
  width: 123px;
  height: 100%; }

.my-team-detail .member-name.blue {
  font-size: 19px;
  font-weight: 600; }

.my-team-detail .member-title {
  font-size: 14px;
  font-weight: 400; }

.my-team-detail .fa-clock-o {
  margin-right: 5px;
  width: 8px;
  height: 8px;
  font-size: 9px;
  font-weight: 600; }

.my-team-detail .member-view .title button {
  margin-left: 0px !important;
  margin-right: 5px !important;
  font-family: open-sans-regular; }

.register .col-sm-6 {
  padding: 0px !important; }

@media only screen and (max-width: 2560px) {
  .title {
    display: flex;
    align-items: center; }
  .member-view {
    padding: 80px !important; }
  .team-name-title {
    margin-left: 35px; }
  .my-team-detail .member-view .title button {
    margin-right: 10px; }
  .my-team-detail .member-view .title {
    font-size: 25px !important;
    display: flex;
    align-items: start;
    font-family: "open-sans-regular"; }
  .my-team-detail .member-name.blue {
    font-size: 20px !important;
    text-transform: capitalize; }
  .my-team-detail .member-time {
    font-family: "open-sans-regular";
    font-size: 10px;
    font-style: normal;
    font-weight: 400; }
  .my-team-detail .member-title {
    font-family: "open-sans-regular";
    font-size: 15px;
    font-style: normal;
    font-weight: 500; } }

@media only screen and (max-width: 1440px) {
  .my-team-detail .member-view .title {
    margin-top: 41.12px;
    color: #00CDAC;
    font-family: "open-sans-regular";
    font-size: 19.6px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; }
  .my-team-detail .info-in {
    height: 112.758px;
    flex-shrink: 0;
    border-radius: 7px;
    background: #FFF;
    box-shadow: 0px 3px 7px 0px #DDDCDC !important; }
  .my-team-detail .ava-member {
    width: 123.2px;
    height: 100%;
    flex-shrink: 0; }
  .my-team-detail .fa-clock-o {
    width: 9px;
    height: 9px; }
  .my-team-detail .member-time {
    color: #7F8FA4;
    font-family: "open-sans-regular";
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; }
  .my-team-detail .member-view .button-view {
    margin-bottom: 0px; }
  .title {
    display: flex;
    align-items: center;
    color: #00CDAC;
    font-family: "open-sans-regular";
    font-size: 19px;
    font-style: normal;
    font-weight: 600; }
  .my-team-detail .member-view .button-view :last-child {
    margin-left: 0px !important;
    margin-right: 24px; }
  .my-team-detail .member-view .title button {
    color: #FFF;
    text-align: center;
    font-family: open-sans-regular;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important; }
  .memberDetails {
    padding-left: 42px !important; }
  .my-team-detail .member-name.blue {
    font-family: "open-sans-regular";
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize; }
  .my-team-detail .member-title {
    font-family: "open-sans-regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize; }
  .member-view {
    padding: 30px !important; }
  .my-team-detail .team-view {
    gap: 25px; }
  .my-team-detail .member-view {
    padding: 60px !important; } }

@media only screen and (max-width: 1024px) {
  .team-name-title {
    margin-left: 15px; }
  .register .col-sm-6:first-child {
    width: 590px; }
  .my-team-detail .info-in {
    width: 590px; }
  .my-team-detail .member-view .title {
    font-family: "open-sans-regular";
    font-size: 17px !important;
    font-weight: 600; }
  .my-team-detail .member-name.red, .my-team-detail .member-name.blue {
    font-size: 15px !important; }
  .my-team-detail .member-title {
    font-size: 12px !important; }
  .my-team-detail .member-view .title button {
    width: 120px !important;
    margin-right: 31px !important;
    font-family: open-sans-regular; } }
